/* .box{
    padding: 7vh;
    border: 2px solid black;
}

 */
table{
    border: 2px solid black;
}
td{
    padding: 4vh;
    border: 2px solid black;
}
th{
    padding: 4vh;
    border: 2px solid black;
}

/* Loader.css */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(126, 126, 126, 0.3); /* Semi-transparent background */
    backdrop-filter: blur(3px); /* Less intense blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is on top of other content */
  }
  
  .loader {
    font-size: 1.5em;
    color: #9e58df;
  }
  